import {
  ColorVariants,
  ColorVariantDescription,
  ThemeColorSection,
} from '@swibeco/types';

const buildColors = (colorVariant: ColorVariants) => {
  switch (colorVariant) {
    case ColorVariants.Danger:
      return {
        variant: 'secondary',
        variantColor: 'main',
      } as ColorVariantDescription<ThemeColorSection>;
    case ColorVariants.Info:
      return {
        variant: 'primary',
        variantColor: 'main',
      } as ColorVariantDescription<ThemeColorSection>;
    default:
      return {
        variant: 'complementary',
        variantColor: 'light',
      } as ColorVariantDescription<ThemeColorSection>;
  }
};

export default buildColors;
