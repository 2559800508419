import React from 'react';
import * as Styles from './styles/Switcher.styles';

export type SwitcherType = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  tag?: React.ReactNode;
  isActive: boolean;
};

/**
 * Template of one switcher/tab
 *
 * @param tag The small information on top of the tab
 * @param isActive
 * @param children
 */
const Switcher = ({ tag, isActive, children, ...rest }: SwitcherType) => (
  <Styles.Switch active={isActive} className="text-center" {...rest}>
    {tag && <Styles.Tag>{tag}</Styles.Tag>}
    <div className="py-4 px-2 d-flex justify-content-center align-items-center h-100">
      {children}
    </div>
  </Styles.Switch>
);

export default Switcher;
