import { useCallback, useContext, useMemo } from 'react';
import { Decimal } from 'decimal.js';
import { EmployeeContributionType, MealTaxDeductionType } from '@swibeco/types';
import { SimulatorStateContext, DataConstraints } from '../components';

const useSimulatorSelectors = () => {
  const { settings, employerConfiguration, employee } = useContext(
    SimulatorStateContext
  );

  const getEmployeeContribution = useCallback(() => {
    switch (employerConfiguration.employeeContributionType) {
      case EmployeeContributionType.Complete:
        return (
          DataConstraints.employerContributionMax -
          employerConfiguration.employerContribution
        );
      case EmployeeContributionType.Equivalent:
        return employerConfiguration.employerContribution;
      default:
        return 0;
    }
  }, [
    employerConfiguration.employeeContributionType,
    employerConfiguration.employerContribution,
  ]);

  const getPartOfEmployerRedistributionCS = useCallback(
    () =>
      new Decimal(
        employerConfiguration.employerSocialContributionRedistributedPercentage
      )
        .div(100)
        .mul(
          new Decimal(
            employerConfiguration.employerSocialContributionPercentage
          ).div(100)
        ),
    [
      employerConfiguration.employerSocialContributionPercentage,
      employerConfiguration.employerSocialContributionRedistributedPercentage,
    ]
  );

  const getAmountDistributedOnTheCard = useCallback(
    () =>
      new Decimal(employerConfiguration.employerContribution).add(
        getEmployeeContribution()
      ),
    [getEmployeeContribution, employerConfiguration.employerContribution]
  );

  const getGrossSalarySacrifice = useCallback(() => {
    /**
     * salaryMethodPref will inherit from the employee salaryMethod that could change if a company offers 2 solutions to credit the Swibeco LunchCard (gross/net).
     * employee.salaryMethod could be equal to net or gross and this value will change if the employee click on different tabs.
     */
    const salaryMethodPref =
      typeof employee !== 'undefined' ? employee.salaryMethod === 'net' : false;

    if (!employerConfiguration.grossSalaryConversion || salaryMethodPref) {
      return new Decimal(0);
    }

    const result1 = new Decimal(DataConstraints.employerContributionMax).sub(
      employerConfiguration.employerContribution
    );

    return new Decimal(
      Math.min(result1.toNumber(), getEmployeeContribution())
    ).dividedBy(new Decimal(1).add(getPartOfEmployerRedistributionCS()));
  }, [
    getEmployeeContribution,
    getPartOfEmployerRedistributionCS,
    employerConfiguration.employerContribution,
    employerConfiguration.grossSalaryConversion,
    employee,
  ]);

  const getEmployeeSocialContributionValue = useCallback(
    () =>
      getGrossSalarySacrifice().times(
        new Decimal(
          employerConfiguration.averageEmployeesSocialContributionPercentage
        ).div(100)
      ),
    [
      getGrossSalarySacrifice,
      employerConfiguration.averageEmployeesSocialContributionPercentage,
    ]
  );

  const getMarginalTaxValue = useCallback(
    () =>
      getGrossSalarySacrifice()
        .minus(getEmployeeSocialContributionValue())
        .times(
          new Decimal(employerConfiguration.averageMarginalTaxRate).div(100)
        ),
    [
      getEmployeeSocialContributionValue,
      getGrossSalarySacrifice,
      employerConfiguration.averageMarginalTaxRate,
    ]
  );

  const getNetPurchasingPowerWithSalary = useCallback(
    () =>
      getGrossSalarySacrifice()
        .minus(getEmployeeSocialContributionValue())
        .minus(getMarginalTaxValue()),
    [
      getEmployeeSocialContributionValue,
      getGrossSalarySacrifice,
      getMarginalTaxValue,
    ]
  );

  const getMealTaxDeductionImpact = useCallback(() => {
    if (
      employerConfiguration.mealTaxDeductionType === MealTaxDeductionType.Full
    ) {
      return new Decimal(1600)
        .dividedBy(12)
        .mul(
          new Decimal(employerConfiguration.averageMarginalTaxRate).div(100)
        );
    }

    return new Decimal(0);
  }, [
    employerConfiguration.averageMarginalTaxRate,
    employerConfiguration.mealTaxDeductionType,
  ]);

  const getEmployerRedistributionCS = useCallback(
    () => getGrossSalarySacrifice().mul(getPartOfEmployerRedistributionCS()),
    [getGrossSalarySacrifice, getPartOfEmployerRedistributionCS]
  );

  const getRestToFinance = useCallback(
    (calculateGrossSalary: boolean) => {
      let restToFinance = getAmountDistributedOnTheCard().minus(
        employerConfiguration.employerContribution
      );

      /**
       * When gross and net salary are enabled by the employer,
       * and that we want to display data of net salary,
       * we need to prevent the calculation of the following
       */
      if (calculateGrossSalary) {
        restToFinance = restToFinance
          .minus(getEmployerRedistributionCS())
          .minus(getGrossSalarySacrifice());
      }

      return restToFinance.isNegative() ? new Decimal(0) : restToFinance;
    },
    [
      getAmountDistributedOnTheCard,
      getEmployerRedistributionCS,
      getGrossSalarySacrifice,
      employerConfiguration.employerContribution,
    ]
  );

  const getNetPurchasingPowerWithLunchCard = useCallback(
    () =>
      getAmountDistributedOnTheCard()
        .minus(getMealTaxDeductionImpact())
        .minus(getRestToFinance(true)),
    [getAmountDistributedOnTheCard, getMealTaxDeductionImpact, getRestToFinance]
  );

  // FIRST INDICATOR
  const getExtraNetPurchasingPower = useCallback(
    () =>
      getNetPurchasingPowerWithLunchCard().minus(
        getNetPurchasingPowerWithSalary()
      ),
    [getNetPurchasingPowerWithLunchCard, getNetPurchasingPowerWithSalary]
  );

  const getEmployeeSCBase = useCallback(() => {
    const employeeSC = new Decimal(
      employerConfiguration.averageEmployeesSocialContributionPercentage
    );

    return new Decimal(employeeSC);
  }, [employerConfiguration.averageEmployeesSocialContributionPercentage]);

  const getIncomeTaxesBase = useCallback(() => {
    const base = new Decimal(100);
    const incomeTaxes = base
      .minus(getEmployeeSCBase())
      .mul(new Decimal(employerConfiguration.averageMarginalTaxRate).div(100));

    return new Decimal(incomeTaxes);
  }, [getEmployeeSCBase, employerConfiguration.averageMarginalTaxRate]);

  const getGrossSalaryEquivalent = useCallback(() => {
    const base = new Decimal(100);
    const increaseInNetPurchasingPower = base
      .minus(getEmployeeSCBase())
      .minus(getIncomeTaxesBase());

    const grossSalaryCoef = new Decimal(100).dividedBy(
      increaseInNetPurchasingPower
    );
    const grossSalaryEquivalent =
      getExtraNetPurchasingPower().mul(grossSalaryCoef);

    return new Decimal(grossSalaryEquivalent);
  }, [getExtraNetPurchasingPower, getEmployeeSCBase, getIncomeTaxesBase]);

  const getEmployeeSCBasedOnGrossSalary = useCallback(() => {
    const base = new Decimal(100);
    const employeeSC = new Decimal(
      getEmployeeSCBase().mul(getGrossSalaryEquivalent()).dividedBy(base)
    );

    return new Decimal(employeeSC);
  }, [getEmployeeSCBase, getGrossSalaryEquivalent]);

  const getIncomeTaxesBasedOnGrossSalary = useCallback(() => {
    const base = new Decimal(100);
    const employeeSC = new Decimal(
      getIncomeTaxesBase().mul(getGrossSalaryEquivalent()).dividedBy(base)
    );

    return new Decimal(employeeSC);
  }, [getIncomeTaxesBase, getGrossSalaryEquivalent]);

  // SECOND INDICATOR
  const getAverageGrossSalaryRaise = useCallback(
    () =>
      getGrossSalaryEquivalent()
        .dividedBy(
          new Decimal(employerConfiguration.averageGrossSalary).dividedBy(12)
        )
        .mul(100),
    [getGrossSalaryEquivalent, employerConfiguration.averageGrossSalary]
  );

  // THIRD INDICATOR
  const getPurchasingPowerMaximisation = useCallback(
    () =>
      getExtraNetPurchasingPower()
        .mul(settings.months)
        .dividedBy(
          new Decimal(180)
            .mul(settings.months)
            .sub(getMealTaxDeductionImpact().mul(settings.months))
        )
        .mul(100),
    [getExtraNetPurchasingPower, getMealTaxDeductionImpact, settings.months]
  );

  // FOURTH INDICATOR
  const getTaxOptimisation = useCallback(
    () =>
      new Decimal(
        new Decimal(employerConfiguration.employerContribution)
          .add(getGrossSalarySacrifice())
          .add(getEmployerRedistributionCS())
      )
        .dividedBy(180)
        .mul(100),
    [
      getEmployerRedistributionCS,
      getGrossSalarySacrifice,
      employerConfiguration.employerContribution,
    ]
  );

  // FIFTH INDICATOR
  const getEmployeesNetParticipation = useCallback(
    () => getNetPurchasingPowerWithSalary().add(getRestToFinance(true)),
    [getNetPurchasingPowerWithSalary, getRestToFinance]
  );

  // SIXTH INDICATOR
  const getTaxSavingsAndEmployersParticipation = useCallback(
    () =>
      getEmployerRedistributionCS()
        .add(employerConfiguration.employerContribution)
        .add(getMarginalTaxValue())
        .add(getEmployeeSocialContributionValue()),
    [
      getEmployeeSocialContributionValue,
      getEmployerRedistributionCS,
      getMarginalTaxValue,
      employerConfiguration.employerContribution,
    ]
  );

  const getGrossSalaryConverted = useCallback(
    () =>
      getNetPurchasingPowerWithSalary()
        .add(getMarginalTaxValue())
        .add(getEmployeeSocialContributionValue()),
    [
      getEmployeeSocialContributionValue,
      getMarginalTaxValue,
      getNetPurchasingPowerWithSalary,
    ]
  );

  const getRedistributedSavingsToEmployees = useCallback(
    () =>
      getGrossSalarySacrifice()
        .mul(employerConfiguration.employerSocialContributionPercentage)
        .div(100)
        .mul(
          employerConfiguration.employerSocialContributionRedistributedPercentage
        )
        .div(100),
    [
      getGrossSalarySacrifice,
      employerConfiguration.employerSocialContributionPercentage,
      employerConfiguration.employerSocialContributionRedistributedPercentage,
    ]
  );

  const getRedistributedSavingsToEmployer = useCallback(
    () =>
      getGrossSalarySacrifice()
        .mul(employerConfiguration.employerSocialContributionPercentage)
        .div(100)
        .mul(
          new Decimal(
            100 -
              employerConfiguration.employerSocialContributionRedistributedPercentage
          )
        )
        .div(100),
    [
      getGrossSalarySacrifice,
      employerConfiguration.employerSocialContributionPercentage,
      employerConfiguration.employerSocialContributionRedistributedPercentage,
    ]
  );

  return useMemo(
    () => ({
      getEmployeeContribution,
      getExtraNetPurchasingPower,
      getGrossSalaryEquivalent,
      getAverageGrossSalaryRaise,
      getPurchasingPowerMaximisation,
      getTaxOptimisation,
      getEmployeesNetParticipation,
      getTaxSavingsAndEmployersParticipation,
      getRestToFinance,
      getGrossSalaryConverted,
      getEmployerRedistributionCS,
      getAmountDistributedOnTheCard,
      getRedistributedSavingsToEmployees,
      getRedistributedSavingsToEmployer,
      getMarginalTaxValue,
      getEmployeeSocialContributionValue,
      getIncomeTaxesBasedOnGrossSalary,
      getEmployeeSCBasedOnGrossSalary,
      getMealTaxDeductionImpact,
    }),
    [
      getEmployeeContribution,
      getExtraNetPurchasingPower,
      getGrossSalaryEquivalent,
      getAverageGrossSalaryRaise,
      getPurchasingPowerMaximisation,
      getTaxOptimisation,
      getEmployeesNetParticipation,
      getTaxSavingsAndEmployersParticipation,
      getRestToFinance,
      getGrossSalaryConverted,
      getEmployerRedistributionCS,
      getAmountDistributedOnTheCard,
      getRedistributedSavingsToEmployees,
      getRedistributedSavingsToEmployer,
      getMarginalTaxValue,
      getEmployeeSocialContributionValue,
      getIncomeTaxesBasedOnGrossSalary,
      getEmployeeSCBasedOnGrossSalary,
      getMealTaxDeductionImpact,
    ]
  );
};

export default useSimulatorSelectors;
