import React from 'react';
import { Text as CText, type TextProps } from '@chakra-ui/react';

type DecorationProps = TextProps & {
  important?: boolean;
  children?: React.ReactNode;
};

const Decoration = ({
  as = 'p',
  children,
  important,
  color,
  whiteSpace,
  ...rest
}: DecorationProps) => (
  <CText
    as={as}
    color={color || 'default.black'}
    whiteSpace={whiteSpace || { lg: 'normal' }}
    variant={important ? 'decoration-important' : 'decoration'}
    pt="16px"
    lineHeight={{ base: '8px', lg: '50px' }}
    data-typo="decoration"
    {...rest}
  >
    {children}
  </CText>
);

export default Decoration;
