import { API } from '@swibeco/shared';
import Decimal from 'decimal.js';
import snakeCaseKeys from 'snakecase-keys';
import {
  SimulatorState,
  SimulatorEmployeeState,
  SimulatorEmployerConfigurationState,
} from '../reducer';
import {
  SalaryMethod,
  MealTaxDeductionType,
  CompanyInformationsType,
  EmployeeContributionType,
  DeliveryCountryType,
} from '@swibeco/types';

type SimulatorEmployerConfigurationResponseType = {
  employer_configuration: {
    number_of_employees: number;
    employer_contribution: number;
    employer_social_contribution_percentage: string;
    employer_social_contribution_redistributed_percentage: string;
    employee_contribution_type: number;
    average_gross_salary: number;
    gross_salary_conversion: boolean;
    net_salary_conversion: boolean;
    average_employees_social_contribution_percentage: string;
    average_marginal_tax_rate: string;
    meal_tax_deduction_type: number;
    net_contract: string;
    gross_contract: string;
  };
};

type SimulatorEmployerResponseType = {
  employer: {
    first_name: string;
    last_name: string;
    profile_picture: string;
    function: string;
    testimony: string;
    testimony_youtube_id: string;
    company_name: string;
    logo: string;
    address: {
      street_address: string;
      complement_address: string;
      postal_code: string;
      city: string;
      country: string;
    };
  };
};

type SimulatorEmployeeResponseType = {
  employee: {
    gender: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    language: string;
    birth_date: string;
    nationality: string;
    address: {
      street_address: string;
      complement_address: string;
      postal_code: string;
      city: string;
      country: string;
    };
    deliveryAddress: {
      addressName: string;
      street_address: string;
      complement_address: string;
      postal_code: string;
      city: string;
      country: string;
    };
    contract_accepted: boolean;
  };
};

type OrderLunchCardRequestType = {
  birthdate: string;
  nationality: string;
  language: string;
  gender: string;
  country: string;
  address: {
    city: string;
    country: string;
    complementAddress: string | undefined;
    postalCode: string;
    streetAddress: string;
  };
  deliveryAddress: {
    name?: string;
    city: string;
    country: string;
    complementAddress: string | undefined;
    postalCode: string;
    streetAddress: string;
  };
  salaryMethod: SalaryMethod;
  averageEmployeesSocialContributionPercentage: number;
  averageMarginalTaxRate: number;
  mealTaxDeductionType: MealTaxDeductionType;
};

type SimulatorResponseType = SimulatorEmployerConfigurationResponseType &
  SimulatorEmployerResponseType &
  SimulatorEmployeeResponseType;

const mapEmployerConfigurationSimulatorResponseToState = (
  response: SimulatorEmployerConfigurationResponseType['employer_configuration']
) => ({
  numberOfEmployees: response.number_of_employees,
  employerContribution: response.employer_contribution,
  employerSocialContributionPercentage: new Decimal(
    response.employer_social_contribution_percentage
  ).toNumber(),
  employerSocialContributionRedistributedPercentage: new Decimal(
    response.employer_social_contribution_redistributed_percentage
  ).toNumber(),
  employeeContributionType:
    response.employee_contribution_type as EmployeeContributionType,
  averageGrossSalary: response.average_gross_salary,
  grossSalaryConversion: response.gross_salary_conversion,
  netSalaryConversion: response.net_salary_conversion,
  averageEmployeesSocialContributionPercentage: new Decimal(
    response.average_employees_social_contribution_percentage
  ).toNumber(),
  averageMarginalTaxRate: new Decimal(
    response.average_marginal_tax_rate
  ).toNumber(),
  mealTaxDeductionType:
    response.meal_tax_deduction_type as MealTaxDeductionType,
  netSalaryConverted: 0,
  grossSalaryConverted: 0,
  amountLoadedOnTheCard: 0,
  netContract: response.net_contract,
  grossContract: response.gross_contract,
});

export default {
  getEmployerConfigurationSimulation: async (
    code: string
  ): Promise<{
    employerConfiguration: SimulatorEmployerConfigurationState;
  }> => {
    const response = await API.get<
      SimulatorEmployerConfigurationResponseType['employer_configuration']
    >(`/flex/simulation/employer/${code}`);

    return {
      employerConfiguration: mapEmployerConfigurationSimulatorResponseToState(
        response.data
      ),
    };
  },
  saveEmployerConfigurationSimulation: async (
    config: SimulatorEmployerConfigurationState,
    informations: CompanyInformationsType
  ) => {
    const payload = {
      ...snakeCaseKeys(config),
      ...snakeCaseKeys(informations),
      function: informations.function || null,
    };

    const response = await API.post<{ code: string }>(
      '/flex/simulation/employer/save',
      payload
    );

    return response.data.code;
  },
  getEmployeeSimulation: async (
    token: string,
    locale: string
  ): Promise<SimulatorState> => {
    const response = await API.get<SimulatorResponseType>(
      `/flex/simulation/employee/${token}`,
      {
        params: {
          locale,
        },
      }
    );

    const employerConfiguration =
      mapEmployerConfigurationSimulatorResponseToState(
        response.data.employer_configuration
      );

    // Set default Gross, unless it is only set true on netSalaryConversion, then we set Net
    const salaryMethod =
      employerConfiguration.netSalaryConversion &&
      !employerConfiguration.grossSalaryConversion
        ? SalaryMethod.Net
        : SalaryMethod.Gross;

    return {
      settings: {
        months: 12,
      },
      employerConfiguration,
      employer: {
        firstName: response.data.employer.first_name,
        lastName: response.data.employer.last_name,
        profilePicture: response.data.employer.profile_picture,
        function: response.data.employer.function,
        testimony: response.data.employer.testimony,
        testimonyYoutubeId: response.data.employer.testimony_youtube_id,
        companyName: response.data.employer.company_name,
        logo: response.data.employer.logo,
        address: {
          street: response.data.employer.address?.street_address,
          complement: response.data.employer.address?.complement_address,
          postalCode: response.data.employer.address?.postal_code,
          city: response.data.employer.address?.city,
          country: response.data.employer.address?.country,
        },
      },
      employee: {
        firstName: response.data.employee.first_name,
        lastName: response.data.employee.last_name,
        email: response.data.employee.email,
        gender: response.data.employee.gender,
        birthDate: response.data.employee.birth_date,
        nationality: response.data.employee.nationality,
        language: response.data.employee.language,
        address: {
          street: '',
          complement: '',
          postalCode: '',
          city: '',
          country: response.data.employee.address?.country,
        },
        deliveryAddress: {
          name: '',
          street: '',
          complement: '',
          postalCode: '',
          city: '',
          country: response.data.employee.address?.country,
        },
        salaryMethod,
        contractAccepted: response.data.employee.contract_accepted,
      },
    };
  },
  orderLunchCard: async (
    token: string,
    employeeState: SimulatorEmployeeState,
    employerConfigurationState: SimulatorEmployerConfigurationState
  ) => {
    const payload: OrderLunchCardRequestType = {
      language: employeeState.language,
      birthdate: employeeState.birthDate,
      nationality: employeeState.nationality,
      gender: employeeState.gender,
      country: employeeState.address.country,
      address: {
        country: employeeState.address.country,
        city: employeeState.address.city,
        streetAddress: employeeState.address.street,
        complementAddress: employeeState.address?.complement,
        postalCode: employeeState.address.postalCode,
      },
      deliveryAddress: {
        name: employeeState.deliveryAddress.name,
        country: DeliveryCountryType.defaultDeliveryCountry,
        city: employeeState.deliveryAddress.city,
        streetAddress: employeeState.deliveryAddress.street,
        complementAddress: employeeState.deliveryAddress?.complement,
        postalCode: employeeState.deliveryAddress.postalCode,
      },
      salaryMethod: employeeState.salaryMethod,
      // eslint-disable-next-line max-len
      averageEmployeesSocialContributionPercentage:
        employerConfigurationState.averageEmployeesSocialContributionPercentage,
      averageMarginalTaxRate: employerConfigurationState.averageMarginalTaxRate,
      mealTaxDeductionType: employerConfigurationState.mealTaxDeductionType,
    };

    await API.put<void>(
      `/flex/simulation/employee/${token}`,
      snakeCaseKeys(payload)
    );
  },
};
