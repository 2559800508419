import 'proxy-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
/* istanbul ignore file */
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { I18nextProvider } from 'react-i18next';
import WebFont from 'webfontloader';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import IEPage from './IEPage';
import { store } from './store/store';
import { coreApi } from '@swibeco/core';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import fr from './translations/fr.json';
import de from './translations/de.json';
import en from './translations/en.json';
import it from './translations/it.json';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['fr', 'en', 'de', 'it'],
    load: 'languageOnly',
    keySeparator: '__',
    returnNull: false,
    react: {
      useSuspense: true,
    },
  });

i18next.addResources('fr', 'translation', fr);
i18next.addResources('en', 'translation', en);
i18next.addResources('de', 'translation', de);
i18next.addResources('it', 'translation', it);

const queryClient = new QueryClient();
const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;

queryClient
  .fetchQuery({ queryKey: ['config'], queryFn: coreApi.getConfig })
  .then((data) => {
    TagManager.initialize({ gtmId: data.gtm_id.general });
    Sentry.init({
      dsn: data.sentry_dsn.general,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  });

WebFont.load({
  typekit: { id: 'jqt1bcb' },
});

const container = document.getElementById('root');
if (!container) throw new Error('container not found!');

const root = createRoot(container);

root.render(
  isIE ? (
    <IEPage />
  ) : (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Provider store={store}>
          <I18nextProvider i18n={i18next}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </I18nextProvider>
        </Provider>
      </HelmetProvider>
    </QueryClientProvider>
  )
);
