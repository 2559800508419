import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SimulatorPage } from '@swibeco/flex-web';
import { ErrorPage } from '@swibeco/core-web';
import { Loader } from '@swibeco/ui';

const Router = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/*" element={<SimulatorPage />} />
        <Route path={'*'} element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
