import React, { Suspense, lazy } from 'react';
import { Simulator } from '@swibeco/flex';
import { Route, Routes } from 'react-router-dom';
import { Loader } from '@swibeco/ui';
import { ErrorBoundary, ErrorPage } from '@swibeco/core-web';
import {
  EMPLOYEE_SIMULATOR_PAGE,
  SAVE_SIMULATION_PAGE,
  SIMULATOR_CONFIRMATION_PAGE,
  SIMULATOR_PRESENTATION_PAGE,
  SIMULATOR_RESULTS_PAGE,
  SIMULATOR_STEPS_PAGE,
} from './paths';

const SimulatorPresentationPage = lazy(
  () => import('../SimulatorPresentationPage/SimulatorPresentationPage')
);
/* istanbul ignore next */
const SimulatorStepsPage = lazy(
  () => import('../SimulatorStepsPage/SimulatorStepsPage')
);
/* istanbul ignore next */
const SimulatorResultsPage = lazy(
  () => import('../SimulatorResultsPage/SimulatorResultsPage')
);
/* istanbul ignore next */
const SaveSimulationPage = lazy(
  () => import('../SaveSimulationPage/SaveSimulationPage')
);
/* istanbul ignore next */
const SimulatorConfirmationPage = lazy(
  () => import('../SimulatorConfirmationPage/SimulatorConfirmationPage')
);
/* istanbul ignore next */
const EmployeeSimulatorPage = lazy(
  () => import('../EmployeeSimulatorPage/EmployeeSimulatorPage')
);

const SimulatorPage = () => (
  <ErrorBoundary>
    <Simulator>
      <div className="d-flex">
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path={SIMULATOR_PRESENTATION_PAGE}
              element={<SimulatorPresentationPage />}
            />
            <Route
              path={SIMULATOR_STEPS_PAGE}
              element={<SimulatorStepsPage />}
            />
            <Route
              path={SAVE_SIMULATION_PAGE}
              element={<SaveSimulationPage />}
            />
            <Route
              path={SIMULATOR_CONFIRMATION_PAGE}
              element={<SimulatorConfirmationPage />}
            />
            <Route
              path={SIMULATOR_RESULTS_PAGE}
              element={<SimulatorResultsPage />}
            />
            <Route
              path={EMPLOYEE_SIMULATOR_PAGE}
              element={<EmployeeSimulatorPage />}
            />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </div>
    </Simulator>
  </ErrorBoundary>
);

export default SimulatorPage;
