import { useCallback, useLayoutEffect, useState } from 'react';

const useLayoutBreakpoint = (breakpoint: number): boolean => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = useCallback(
    () => setWidth(window.innerWidth),
    []
  );

  useLayoutEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  return width <= breakpoint;
};

export default useLayoutBreakpoint;
