/* istanbul ignore file */
// No need to be tested, it doesnt have any specific logic
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import coreApi from '../api/coreApi';
import { selectors } from '../coreSlice';

const useNationalities = () => {
  const locale = useSelector(selectors.getLocale);
  return useQuery({
    queryFn: () => coreApi.getNationalities(locale),
    queryKey: ['nationalities', locale],
    retry: false,
  });
};

export default useNationalities;
