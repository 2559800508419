import 'proxy-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { styled, breakpoints } from '@swibeco/shared';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;

  ${breakpoints.md} {
    width: 50%;
  }
`;

const Image = styled.img``;

const Text = styled.p`
  text-align: center;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.default.dark};
  line-height: 1.1;
  font-family: 'Segoe UI' !important;
`;

const IEPage = () => {
  const userLang = navigator.language;

  return (
    <Container>
      <Wrapper>
        <Image
          src="/static/img/placeholders/warning_icon.png"
          className="mx-auto d-block mb-4 img-fluid"
          alt="Warning logo"
          width="110"
        />
        <Text>
          {userLang.startsWith('fr')
            ? `Tu utilises actuellement Internet Explorer comme navigateur, cette version n'est 
              malheureusement pas supportée par l'application Swibeco. Nous te recommandons d'utiliser 
              Chrome ou Firefox pour une expérience optimale.`
            : userLang.startsWith('de')
            ? `Du verwendest den Internet Explorer als Web-Browser, leider wird diese Version nicht mehr von 
              Swibeco unterstützt. Für eine optimale Nutzung, verwende am besten Chrome oder Firefox.`
            : `You are using Internet Explorer as browser, unfortunately this version is not supported 
              on Swibeco application. We strongly recommend you to use Chrome or Firefox for a better experience.`}
        </Text>
      </Wrapper>
    </Container>
  );
};

export default IEPage;
