import { Box } from '@chakra-ui/react';
import { useLanguageSwitcher } from '@swibeco/core';
import { Footer, Header } from '@swibeco/shared-web';
import { ThemeType } from '@swibeco/types';
import { Loader, SwibecoColors, UIProvider, customTheme } from '@swibeco/ui';
import React, { Suspense, useEffect, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from 'routing/Router';

const App = () => {
  const theme: ThemeType = useMemo(() => SwibecoColors, []);
  const { setLocale } = useLanguageSwitcher();
  // default language should be french
  useEffect(() => {
    setLocale('fr');
  }, []);
  return (
    <UIProvider theme={theme} customTheme={customTheme}>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Box display="flex" minH="100%" minWidth="100%">
            <Box flex={1}>
              <Header />
              <Box minHeight="70vh" width="100vw">
                <Router />
              </Box>
              <Footer />
            </Box>
          </Box>
        </BrowserRouter>
      </Suspense>
    </UIProvider>
  );
};
export default App;
