import { useContext } from 'react';
import { Decimal } from 'decimal.js';
import { EmployeeContributionType } from '@swibeco/types';
import {
  DataConstraints,
  SimulatorStateContext,
} from '../components/Simulator';

const ponderationTable = {
  fullAllocation: 10,
  partialAllocation: 4.5,
  grossSalary: 2,
  netSalary: 1,
  powerBooster: 2,
};

const scenarioTable = {
  4.5: 1,
  5.5: 2,
  6.5: 3,
  7.5: 4,
  8.5: 5,
  9.5: 6,
  4: 7,
  2: 8,
  11: 9,
  10: 10,
};

const useScenario = () => {
  const { employerConfiguration } = useContext(SimulatorStateContext);

  let points = new Decimal(0);

  if (
    employerConfiguration.employerContribution ===
    DataConstraints.employerContributionMax
  ) {
    points = points.add(ponderationTable.fullAllocation);
  } else if (
    employerConfiguration.employerContribution >
    DataConstraints.employerContributionMin
  ) {
    points = points.add(ponderationTable.partialAllocation);
  }

  if (
    employerConfiguration.employeeContributionType !==
    EmployeeContributionType.NoParticipation
  ) {
    if (
      employerConfiguration.employerSocialContributionRedistributedPercentage >
        0 &&
      employerConfiguration.grossSalaryConversion
    ) {
      points = points.add(ponderationTable.powerBooster);
    }

    if (employerConfiguration.grossSalaryConversion) {
      points = points.add(ponderationTable.grossSalary);
    }

    if (employerConfiguration.netSalaryConversion) {
      points = points.add(ponderationTable.netSalary);
    }
  }

  return scenarioTable[points.toNumber()];
};

export default useScenario;
